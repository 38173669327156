import { createApp } from 'vue'
import App from './App.vue'

//引入JS
import 'bootstrap/dist/js/bootstrap'

import 'jquery'
import 'jquery-ui/dist/jquery-ui.min'
import './assets/js/app'
import "default-passive-events"
// import "@/assets/js/shopCart"
import { checkAdminToken, checkUserToken } from './utils/tokenUtils'

//引入CSS
import 'bootstrap/dist/css/bootstrap.min.css'
import 'jquery-ui/themes/base/all.css'
import './assets/css/line-awesome.css'
import './assets/css/style.css'
import './assets/css/responsive.css'

//引入router
import router from './router/index'

const app = createApp(App);
app.use(router);
app.mount('#app');
router.beforeEach(async guard => {
    if (guard.name === "ProductPreview") {
        if (guard.query.t) {
            let result = await checkAdminToken(guard.query.t);
            localStorage.setItem("t", guard.query.t);
            if (result) {
                localStorage.clear();
                router.replace({ path: "/" });
            }
        } else {
            router.replace({ path: "/" });
        }
    } else {
        if (guard.name === "Account" ||
            guard.name === "AccountMain" ||
            guard.name === "UserInfo" ||
            guard.name === "ChangePassword" ||
            guard.name === "Address" ||
            guard.name === "MyOrder" ||
            guard.name === "MyCollect" ||
            guard.name === "History" ||
            guard.name === "ShopCart" ||
            guard.name === "OrderCompleted" ||
            guard.name === "ProductView" ||
            guard.name === "Search" ||
            guard.name === "PayOrder" ||
            guard.name === "PaymentOrder" ||
            guard.name === "PackagePay" ||
            guard.name === "OrderCompleted"

        ) {
            let result = await checkUserToken();
            if (result) {
                localStorage.clear();
                router.replace({ path: "/login", query: { msg: result.msg } });
            }
        }
    }
});