import { request } from "./../../index";
import { adminRequest } from "@/network";
const prefix = '/common-api';

export function validateUserToken(token) {
  return request({
    url: `${prefix}/validateToken`,
    params: {
      token: token,
      type: 1
    }
  });
}

export function validateAdminToken(token) {
  return adminRequest({
    url: `${prefix}/validateToken`,
    params: {
      token: token,
      type: 2
    }
  });
}
/**
 * 获取邮件验证码
 * @param email 邮箱账号
 * @param type 类型 3-注册 4-找回密码
 * @returns {AxiosPromise}
 */
export function getMailCaptcha(email, type) {
    return request({
        url: `${prefix}/sendMailCaptcha`,
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        method: "post",
        data: { email, type }
    });
}

/**
 * 获取登录验证码
 * @returns {AxiosPromise}
 */
export function getCaptchaImage(email) {
    return request({
        url: `${prefix}/generatorCaptcha`,
        params: {
            type: 1,
            email: email
        }
    });
}

export function uploadAvatar(file, fileName) {
    let formData = new FormData();
    formData.append('file', file, fileName);
    return request({
        url: `${prefix}/uploader/user?type=8`,
        method: "post",
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}
