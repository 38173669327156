import { createRouter, createWebHistory } from 'vue-router'

const routes = [{
        name: "Home",
        path: "/",
        component: () =>
            import ("@/pages/Home")
    },
    {
        // 注册
        name: "Register",
        path: "/register",
        component: () =>
            import ("@/pages/Register")
    },
    {
        // 登陆
        name: "Login",
        path: "/login",
        component: () =>
            import ("@/pages/Login")
    },
    {
        // 密码重置
        name: "ForgotPassword",
        path: "/forgotPassword",
        component: () =>
            import ("@/pages/ForgotPassword")
    },
    {
        // 我的账户
        name: "Account",
        path: "/account",
        redirect: "/accountMain",
        component: () =>
            import ("@/pages/mine/Account"),
        children: [{
                // 账户首页
                name: "AccountMain",
                path: "/accountMain",
                redirect: "/UserInfo"
            },
            {
                // 修改用户信息
                name: "UserInfo",
                path: "/userInfo",
                component: () =>
                    import ("@/pages/mine/UserInfo")
            },
            {
                // 修改密码
                name: "ChangePassword",
                path: "/changePassword",
                component: () =>
                    import ("@/pages/mine/ChangePassword")
            },
            {
                // 修改地址
                name: "Address",
                path: "/address",
                component: () =>
                    import ("@/pages/mine/Address")
            },
            {
                // 我的订单
                name: "MyOrder",
                path: "/myOrder",
                component: () =>
                    import ("@/pages/mine/MyOrder")
            },
            {
                // 浏览历史
                name: "History",
                path: "/history",
                component: () =>
                    import ("@/pages/mine/History")
            },

        ]
    },
    {
        // 购物车
        name: "ShopCart",
        path: "/shopCart",
        component: () =>
            import ("@/pages/mine/ShopCart")
    },
    {
        // 我的收藏
        name: "MyCollect",
        path: "/myCollect",
        component: () =>
            import ("@/pages/mine/MyCollect")
    },
    {
        // 商品详情
        name: "ProductView",
        path: "/productView",
        component: () =>
            import ("@/pages/product/ProductView")
    },
    {
        // 关于谷喵
        name: "AboutUs",
        path: "/aboutUs",
        component: () =>
            import ("@/pages/bottom/AboutUs")
    },
    {
        // 资费说明
        name: "Charges",
        path: "/charges",
        component: () =>
            import ("@/pages/bottom/Charges")
    },
    {
        // 公司信息
        name: "CompanyInfo",
        path: "/companyInfo",
        component: () =>
            import ("@/pages/bottom/CompanyInfo")
    },
    {
      // 文章
      name: "Article",
      path: "/article",
      component: () =>
        import ("@/pages/Article")
    },
    {
        // 购物指南
        name: "ShoppingGuide",
        path: "/shoppingGuide",
        component: () =>
            import ("@/pages/bottom/ShoppingGuide")
    },
    {
        // 商品预览
        name: "Search",
        path: "/search",
        component: () =>
            import ("@/pages/product/Search")
    },
    {
        // admin商品详情
        name: "ProductPreview",
        path: "/productPreview",
        component: () =>
            import ("@/pages/product/ProductPreview")
    },
    {
        // 页面丢失
        name: "PageMissing",
        path: "/:catchAll(.*)",
        component: () =>
            import ("@/pages/PageMissing")
    },

    {
        // 支付系统
        name: "PayOrder",
        path: "/payOrder",
        component: () =>
            import ("@/pages/order/PayOrder"),
        children: [{
                // 订单支付
                name: "PaymentOrder",
                path: "/paymentOrder",
                component: () =>
                    import ("@/pages/order/PaymentOrder")
            }, {
                // 包裹
                name: "PackagePay",
                path: "/packagePay",
                component: () =>
                    import ("@/pages/order/PackagePay")
            },
            {
                // 支付成功
                name: "OrderCompleted",
                path: "/orderCompleted",
                component: () =>
                    import ("@/pages/order/OrderCompleted")
            },
        ]
    }, {
        // 支付
        name: "Pay",
        path: "/pay",
        component: () =>
            import ("@/pages/order/Pay")
    }, {
        // 支付
        name: "ExpressPay",
        path: "/expressPay",
        component: () =>
            import ("@/pages/order/ExpressPay")
    },




];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;
