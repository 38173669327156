<template>
  <div class="mask">
    <div class="text-center myLoading">
      <div class="spinner-border text-danger" role="status"></div>
      <div style="color:#dc3545;font-weight:bold;">{{ text }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Loading",
  props: {
    text: {
      default: function () {
        return "Loading..."
      },
      type: String
    }
  }
}
</script>

<style scoped>

</style>
