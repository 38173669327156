import { validateAdminToken, validateUserToken } from "@/network/api/common-api/common-api";
import { MyAlert } from "@/components/components";
import jwtDecode from "jwt-decode";
import router from "@/router";

export function getPayload() {
    let token = localStorage.getItem("token");
    let payload;
    try {
      if (token) {
        payload = jwtDecode(token);
      } else {
        console.log("token is null or undefined");
      }
    }catch (e){
      localStorage.clear();
      router.push({ path: "/login", query: { msg: "请先登录" } });
    }
    return payload;
}

export async function checkUserToken() {
    let flag;
    let token = localStorage.getItem("token");
    if (token) {
        let result = await validateUserToken(token);
        if (result.code !== 0) {
            if (result.code === 9004) {
                flag = { msg: "登录超时" }
            } else {
                flag = { msg: "请先登录" }
            }
        } else {
            localStorage.setItem("token", result.data.token);
        }
    } else {
        flag = { msg: "请先登录" };
    }
    return flag;
}

export async function checkAdminToken(token) {
    let flag;
    if (token) {
        let result = await validateAdminToken(token);
        if (result.code !== 0) {
            if (result.code === 9004) {
                flag = { msg: "登录超时" }
            } else {
                flag = { msg: "请先登录" }
            }
        }
    } else {
        flag = { msg: "请先登录" };
    }
    return flag;
}

export function redirectLogin(res) {
    if (res.code && (res.code === 9004 || res.code === 9005)) {
        localStorage.clear();
        if (res.code === 9004) {
            router.push({ path: "/login", query: { msg: "登录超时" } });
        } else {
            router.push({ path: "/login", query: { msg: "请先登录" } });
        }
    }
}

export function redirectLoginByToken() {
    MyAlert("warning", "请先登录", 1500);
    const timeout = setTimeout(() => {
        clearTimeout(timeout);
        router.push({ path: "/login" });
    }, 300);
}
