import Alert from "@/components/Alert";
import Loading from "@/components/Loading";
import { createVNode, render } from "vue";

const alertDiv = document.createElement('div');
alertDiv.setAttribute('class', 'alert-container');
document.body.appendChild(alertDiv);

const loadingDiv = document.createElement('div');
loadingDiv.setAttribute('class', 'loading-container');
document.body.appendChild(loadingDiv);

export function MyAlert(type, msg, time) {
    const vNode = createVNode(Alert, { type, msg });
    const scrollY = window.scrollY;
    render(vNode, alertDiv);
    alertDiv.children[1].style.marginTop = scrollY + 50 + "px";
    alertDiv.children[1].classList.add('Z999');
    const timeout = setTimeout(() => {
        render(null, alertDiv);
        clearTimeout(timeout);
    }, time);
}

export function MyLoading(visible, text) {
    const vNode = createVNode(Loading, { text });
    if (visible) {
        render(vNode, loadingDiv);
    } else {
        render(null, loadingDiv);
    }
}