import axios from 'axios';
import {redirectLogin} from "@/utils/tokenUtils";
import {MyAlert} from "@/components/components";

export function request(config) {
    // 1.创建axios的实例
    const instance = axios.create({
        baseURL: '/api',
        timeout: 30000
    })

    // 2.axios的拦截器
    // 2.1.请求拦截的作用
    instance.interceptors.request.use(config => {
      let token = localStorage.getItem("token");
      if (token) {
        config.headers["Authorization"] = "Bearer " + token
      }
        return config
    }, err => {
        console.log(err);
    })

    // 2.2.响应拦截
    instance.interceptors.response.use(res => {
        console.log(res.data);
        if (res.data.data && res.data.data.token) {
          localStorage.setItem("token", res.data.data.token);
        }
        if(res.data.code && (res.data.code === 9004 || res.data.code === 9005)){
          redirectLogin(res.data);
        }
        return res.data
    }, err => {
      console.log(err);
      if(err?.message === "Request failed with status code 503"){
        MyAlert("error","网关异常",1500);
      }
      if(err?.message.indexOf("timeout") > -1){
        MyAlert("error","连接超时",1500);
      }
    })

    // 3.发送真正的网络请求
    return instance(config)
}

export function adminRequest(config) {
    // 1.创建axios的实例
    const instance = axios.create({
        baseURL: '/api',
        timeout: 5000
    })

    // 2.axios的拦截器
    // 2.1.请求拦截的作用
    instance.interceptors.request.use(config => {
        let token = localStorage.getItem("t");
        if (token) {
            config.headers["Authorization"] = "Bearer " + token
        }
        return config
    }, err => {
        console.log(err);
    })

    // 2.2.响应拦截
    instance.interceptors.response.use(res => {
        console.log(res.data);
        if (res.data.data && res.data.data.token) {
            localStorage.setItem("t", res.data.data.token);
        }
        return res.data
    }, err => {
        console.log(err);
    })

    // 3.发送真正的网络请求
    return instance(config)

}
